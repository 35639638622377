// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"5a08635238f84ec463eba3f4ec0b3636d9671dce"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";globalThis["_sentryAssetPrefix"] = undefined;globalThis["_sentryExperimentalThirdPartyOriginStackFrames"] = undefined;import * as Sentry from "@sentry/nextjs";
import posthog from "posthog-js";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",

  dsn: "https://1d61feecc13a0b2ba3071dccceb4418a@o4506111518507008.ingest.us.sentry.io/4506111519752192",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    posthog.sentryIntegration({
      organization: "usturlap",
      projectId: 4506111519752192,
      severityAllowList: ["error", "info"], // optional: here is set to handle captureMessage (info) and captureException (error)
    }),
  ],
});
